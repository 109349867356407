<template>
  <!-- 发帖/回复弹窗 -->
  <div class="my-modal">
    <div v-drag>
      <a-modal v-model:visible="dialogVisible"
               :maskClosable="false"
               width="1050rem"
               destroyOnClose
               :closable='false'
               :bodyStyle="{padding:'15rem 24rem 10rem',height:bodyHeight,overflowY:'scroll'}"
               @cancel="handleClose">
        <template #title>
          <span style="font-size:24rem">{{title}}</span>
        </template>

        <a-form :model="form"
                :rules="rules"
                ref="formRef"
                :label-col="{span:3}">

          <a-row :gutter="10"
                 v-if='type==1'>
            <a-col :span="23">
              <a-form-item label="科目:"
                           name="board_id">
                <a-select placeholder="请选择"
                          allowClear
                          :disabled='isDisable'
                          v-model:value="form.board_id"
                          :options='subjectList1.map((item,i) => ({ label: item.board_name,value:item.board_id }))'>

                </a-select>
              </a-form-item>

            </a-col>
          </a-row>
          <a-row :gutter="10"
                 v-if='type==1'>
            <a-col :span="23">
              <a-form-item label="标题:"
                           name="discuss_title">
                <a-input placeholder="请输入标题"
                         v-model:value="form.discuss_title"
                         style="width:100%" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="10">
            <a-col :span="23">
              <a-form-item label="内容:"
                           name="discuss_content">
                <a-textarea placeholder="请输入内容"
                            style="width:100%"
                            v-model:value="form.discuss_content"
                            :rows="textRow" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="10"
                 v-if='type==1'>
            <a-col :span="23">
              <a-form-item label="题目:">
                <a-input style="width:100%"
                         :disabled='isDisable'
                         v-model:value="form.question_id"
                         placeholder="输入题目id，请仔细检查题目ID是否正确。" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="10">
            <a-col :span="23">
              <a-form-item label="图片:">
                <myImg ref='imgRef' />
              </a-form-item>
            </a-col>
          </a-row>

        </a-form>
        <template #footer>
          <div class="btn_warp">
            <div @click="handleClose">{{cancelText}}</div>
            <div @click="save">{{okText}}</div>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
</template>


<script setup>
import { ref, defineProps, onMounted } from "vue"
import { message } from 'ant-design-vue'
import myImg from '@/views/components/uploadImage/image.vue' //上传图片组件
import { getDiscussBoard } from '@/api/post.js'
// 获取帖子内容板块

let { subjectList, title, okText, cancelText, type, bodyHeight, textRow, isDisable } = defineProps({
  subjectList: {
    type: Array,
    default: [
      /*    {
         board_name: '',
         board_id: 0
       } */
    ]
  },
  title: {
    type: String,
    default: '发帖'
  },
  bodyHeight: {
    type: String,
    default: '690rem'
  },
  okText: {
    type: String,
    default: '确定'
  },
  textRow: {
    type: Number,
    default: 6
  },
  cancelText: {
    type: String,
    default: '取消'
  },
  type: {
    type: String,
    default: '0'
  },
  isDisable: {//在题目里发帖，则科目和题目id不可修改
    type: Boolean,
    default: false
  }

})
let imgRef = ref()
let dialogVisible = ref(false)
let form = ref({})
let images = ref([])
let rules = {
  discuss_title: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  discuss_content: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  board_id: [
    { required: true, message: '请选择', trigger: 'change' },
  ],
}
let formRef = ref()
let subjectList1 = ref()
subjectList1.value = subjectList
let form1 = ref([])

const save = () => {//提交，先检测数据，再调用父组件方法
  images.value = imgRef.value.images
  formRef.value.validate()
    .then((val) => {
      if (!val) return
      form1.value = JSON.parse(JSON.stringify(form.value))
      // if (form1.value.question_id) {
      //   form1.value.question_id = form1.value.question_id.slice(4, form1.value.question_id.length)
      // }
      form1.value.discuss_image = images.value.join(',')
      emit('submit')
    })
    .catch(error => {
      console.log('error', error);
    })
}
let getSubject = async () => {
  const { data } = await getDiscussBoard()
  subjectList1.value = data
}
onMounted(() => {
  // 若是父组件没传板块列表则请求获取
  if (subjectList1.value.length <= 0) {
    // 获取帖子内容板块
    getSubject()
  }
})

// 关闭弹窗
const handleClose = () => {
  imgRef.value.images = []
  emit('cancel')
}
defineExpose({
  dialogVisible, subjectList, form, form1
})
const emit = defineEmits(['submit', 'cancel'])

</script>

<style lang="scss" scoped>
.btn_warp {
  // border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin-bottom: -30rem;
  div {
    flex: 1;
    font-weight: bold;
    font-size: 24rem;
    // padding: 18rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1px solid #e5e5e5;
    }
  }
}

.my-modal {
  .ant-modal-content {
    overflow-x: clip !important;
  }
  .ant-modal-header {
    padding-bottom: 0;
  }
  .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 24rem;
  }
}
:deep .ant-modal-footer {
  height: auto !important;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
  margin-right: 25rem;
}
:deep .ant-form-item {
  font-size: 24rem;
}
:deep .ant-input,
.ant-select {
  font-size: 24rem;
}
:deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 11px;
  height: auto;
}
:deep .ant-form-item-with-help .ant-form-item-explain {
  font-size: 20rem;
  line-height: 33rem;
}
</style>