<template>
  <div class="images">
    <div v-for="(item) in images"
         :key="item"
         class='img_wrap'>
      <a-popconfirm title="确定删除这个图片?"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="delImage(item,index)">
        <img class="close"
             src="@/static/del.png"
             alt=""
             width="120rem"
             height="120rem">
      </a-popconfirm>
      <a-image :src="item"
               alt=""> </a-image>
    </div>
    <div class="add_img"
         v-if="images.length  != imgNum"
         @click="uploadImage">
      <div>
        <img src="@/static/dayi/bg-上传图片.png"
             alt="点击上传图片"
             title="点击上传图片">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { uploadImageAuto } from '@/api/upload.js'
import { addHeader as globalAddHeader } from '@/init/index.js'
import { message } from 'ant-design-vue'
let images = ref([])

let { imgNum } = defineProps({
  imgNum: {//上传图片最大数量
    type: String,
    default: "2"
  }
})

const addHeader = globalAddHeader
//上传图片
const uploadImage = async () => {
  const { data } = await uploadImageAuto(20, "image/*")
  images.value.push(addHeader(data.dir))
  message.success('上传成功')
  // let userId = JSON.parse(window.localStorage.getItem('userInfo')).user_id
  // window.injectedObject.imageClick(userId, 0)
}
const delImage = (item, index) => {
  images.value.splice(index, 1)
}
defineExpose({
  images
})
</script>

<style lang='scss' scoped>
.add_img {
  width: 120rem;
  height: 120rem;
  font-weight: 500;
  background: #f6f6f6;
  border-radius: 5px;
  text-align: center;
  color: #2196f3;
  border-radius: 5px;
  border: 1px dashed transparent;
  cursor: pointer;
  &:hover {
    border: 1px dashed #2196f3;
    overflow: hidden;
  }
  img {
    width: 120rem;
    height: 120rem;
  }
}
.img_wrap {
  position: relative;
  width: 120rem;
  height: 120rem;
  margin-right: 30rem;
  :deep .ant-image {
    width: 100%;
  }
  .close {
    position: absolute;
    right: -5px;
    top: -5px;
    background: red;
    color: white;
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    z-index: 19;
  }
}

.images {
  display: flex;
}
</style>